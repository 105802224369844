import { ListItem, ListItemButton, Stack, Typography } from "@mui/material";
import React from "react";

export interface InterventionListItemProps {
  indiviualsName: string,
  streetWorkName: string,
  streetWorkTeamName: string,
  date: string,
  interventionType: string
}

export const InterventionListItem: React.FC<InterventionListItemProps> = (props: InterventionListItemProps) => {

  const getBorderColor = () => {
    switch(props.interventionType.toLowerCase())
    {
      case "routine" :
        return "primary.light";
      case "individuelle" :
        return "secondary.main";
      default:
        return "info.main";
    }
  };

  return (
    <ListItem disablePadding sx={{py:"1px", pl:"1px"}}>
      <ListItemButton disableGutters sx={{px:1, borderLeftWidth:4, borderLeftStyle:"solid", borderLeftColor: getBorderColor()}}>
        <Stack direction='row' justifyContent="space-between" sx={{flexGrow:1}}>
          <Stack>
            <Typography variant='body1' fontWeight='600'>{props.indiviualsName}</Typography>
            <Typography variant='body2' color='grey.500' lineHeight='1'>{props.streetWorkName}</Typography>
            <Typography variant='body2' color='grey.500' lineHeight='1'>{props.streetWorkTeamName}</Typography>
          </Stack>
          <Stack alignItems="flex-end" justifyContent="space-between">
            <Typography variant='body1' color='grey.500'>{props.date}</Typography>
            <Typography variant='body2' color='grey.500' lineHeight='1'>{props.interventionType}</Typography>
          </Stack>
        </Stack>
      </ListItemButton>
    </ListItem>
  );
};