import React from "react";
import { ListItem, ListItemButton, Stack, InputLabel, FormControl, Typography } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { SelectDialogItemValue } from "./types";
import { SelectDialog } from "./SelectDialog";

export interface SelectDialogInputProps {
  label: string,
  dialogTitle: string,
  options: SelectDialogItemValue[],
  value: string,
  onChange: (selected: SelectDialogItemValue) => void,
  required?: boolean
}

export const SelectDialogInput: React.FC<SelectDialogInputProps> = (props: SelectDialogInputProps) => {

  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [selected, setSelected] = React.useState<SelectDialogItemValue>();

  const handleClickOpenButton = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleOnSelected = (selection: SelectDialogItemValue) => {
    setSelected(selection);
    props.onChange(selection);
    setIsDialogOpen(false);
  };

  return (
    <>
      <FormControl fullWidth sx={{borderBottomColor: "text.disabled", borderBottomStyle:"solid", borderBottomWidth: 1}} required={props.required}>
        <InputLabel variant='standard' shrink>{props.label}</InputLabel>
        <ListItem disablePadding sx={{mt:2}}>
          <ListItemButton disableGutters sx={{py:1/2}} onClick={handleClickOpenButton}>
            <Stack direction='row' justifyContent="space-between" sx={{flexGrow:1}}>
              <Stack direction='row' gap={1/2} flexWrap="wrap">
                {selected == null ? (
                  <Typography>Sélectionner...</Typography>
                ) : (
                  <Typography>{selected?.text}</Typography>
                )}
              </Stack>
            </Stack>
            <ChevronRightIcon />
          </ListItemButton>
        </ListItem>
      </FormControl>
      <SelectDialog
        title={props.dialogTitle}
        isDialogOpen={isDialogOpen} 
        handleDialogClose={handleDialogClose} 
        value={props.options} 
        onChange={handleOnSelected}
      />
    </>
  );
};