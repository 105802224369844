import React from "react";
import { ListItem, ListItemButton, Stack, InputLabel, FormControl, Chip, Typography } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import sortBy from "lodash/sortBy";
import { SelectSpecificationItemValue } from "./types";
import { SelectSpecificationsDialog } from "./SelectSpecificationsDialog";
import { RealityTheme } from "types";

export interface SelectSpecificationsInputProps {
  title: string,
  label: string,
  value: RealityTheme[],
  onChange: (selected: SelectSpecificationItemValue) => void
}

export const SelectSpecificationsInput: React.FC<SelectSpecificationsInputProps> = (props: SelectSpecificationsInputProps) => {

  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [selected, setSelected] = React.useState<SelectSpecificationItemValue[]>([]);

  const handleClickOpenButton = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleOnSelected = (selection: SelectSpecificationItemValue[]) => {
    setSelected(selection);
  };

  return (
    <>
      <FormControl fullWidth required sx={{borderBottomColor: "text.disabled", borderBottomStyle:"solid", borderBottomWidth: 1}}>
        <InputLabel variant='standard' shrink>{props.label}</InputLabel>
        <ListItem disablePadding sx={{mt:2}}>
          <ListItemButton disableGutters sx={{py:1/2}} onClick={handleClickOpenButton}>
            <Stack direction='row' justifyContent="space-between" sx={{flexGrow:1}}>
              <Stack direction='row' gap={1/2} flexWrap="wrap">
                {selected.map((item)=>(
                  <Chip key={item.id} label={`${item.theme.name} - ${item.name}`} size="small"/>
                ))}
                {selected.length === 0 && (
                  <Typography>Sélectionner...</Typography>
                )}
              </Stack>
            </Stack>
            <ChevronRightIcon />
          </ListItemButton>
        </ListItem>
      </FormControl>
      <SelectSpecificationsDialog
        title={props.title}
        value={props.value}
        isDialogOpen={isDialogOpen} 
        handleDialogClose={handleDialogClose} 
        selected={sortBy(selected, ["name"])}
        onChange={handleOnSelected}
      />
    </>
  );
};