import React from "react";
import { MobileDatePicker } from "@mui/lab";
import { Container, Paper, Typography, Divider, Grid, TextField, InputAdornment, InputLabel, ToggleButtonGroup, ToggleButton } from "@mui/material";
import { Box } from "@mui/system";
import { MultiPickerInput } from "components/MultiPicker";
import { SinglePickerInput } from "components/SinglePicker";
import EventIcon from "@mui/icons-material/Event";
import { MultipickerItemValue } from "components/MultiPicker/types";
import { SinglepickerItemValue } from "components/SinglePicker/types";
import { SelectDialogItemValue } from "components/SelectDialog/types";
import { SelectDialogInput } from "components/SelectDialog";
import sortBy from "lodash/sortBy";
import deburr from "lodash/deburr";

const socialWorkers: MultipickerItemValue[] = [
  { id: 1, name: "Helen Moss", avatarSrc: "https://i.pravatar.cc/300?t=1" },
  { id: 2, name: "Brenda Turner", avatarSrc: "https://i.pravatar.cc/300?t=2" },
  { id: 3, name: "Jeremy Seward", avatarSrc: "https://i.pravatar.cc/300?t=3" },
  { id: 4, name: "Glenn Abbott", avatarSrc: "https://i.pravatar.cc/300?t=4" },
  { id: 5, name: "Harry Lloyd", avatarSrc: "https://i.pravatar.cc/300?t=5" },
];

const teams: SinglepickerItemValue[] = [
  { id: 1, text: "Bénévoles", imageSrc: "https://lalande.ecoleouestmtl.com/wp-content/uploads/2017/11/logo-benevoles-300x297.jpg" },
  { id: 2, text: "Direction", imageSrc: "https://www.creativefabrica.com/wp-content/uploads/2020/03/06/Direction-Logo-Graphics-3183080-1.jpg" },
  { id: 3, text: "Service d’intervenants", imageSrc: "" },
  { id: 4, text: "Groupe d’entraide", imageSrc: "https://st.depositphotos.com/1364916/1400/v/950/depositphotos_14006320-stock-illustration-teamwork-union-logo-vector.jpg" },
];

const reachoutOptions: SelectDialogItemValue[] = [
  { id: 1, text: "Bureau" },
  { id: 2, text: "Cellulaire" },
  { id: 3, text: "Personne interposée" },
  { id: 4, text: "Présentation"},
  { id: 5, text: "Référé par un intervenant"},
  { id: 6, text: "Rendez-vous"},
];

const meetingPlaceOptions: SinglepickerItemValue[] = [
  { id: 1, text: "À l’organisme" },
  { id: 2, text: "Domicile" },
  { id: 3, text: "Institution publique" },
  { id: 4, text: "Organisme communautaire"},
  { id: 5, text: "Infrastructure municipale"},
  { id: 6, text: "Commerce"},
  { id: 7, text: "Commerce 18 ans et +"},
  { id: 8, text: "Rue/Parc/Ruelle"},
  { id: 9, text: "Espace marginal"},
];

const linksOptions: SelectDialogItemValue[] = [
  { id: 1, text: "Consolidation" },
  { id: 2, text: "Lien consolidé" },
  { id: 3, text: "Premier contact" },
];

const comsumationOptions: SelectDialogItemValue[] = [
  { id: 1, text: "Oui" },
  { id: 2, text: "Non" },
  { id: 3, text: "Inconnu" },
  { id: 4, text: "Occasionnel"},
  { id: 5, text: "Abstinent"},
];

export const NewInterventionSection1: React.FC = () => {

  const [interventionType, setInterventionType] = React.useState("individuelle");

  const handleOnExtraWorkerChange = (selected: MultipickerItemValue) =>{
    console.log(selected);
  };

  const handleOnTeamChange = (selected: SinglepickerItemValue) => {
    console.log(selected);
  };

  const handleOnReachOutChange = (selected: SelectDialogItemValue) => {
    console.log(selected);
  };

  return (
    <Container disableGutters sx={{py:2, px:1}}>
      <Paper>
        <Box sx={{p:1}}>
          <Typography variant="h6" component="h2" fontWeight={700}>Détails</Typography>
        </Box>
        <Divider />
        <Grid container spacing={2} sx={{p:1}}>
          <Grid item xs={12} md={6}>
            <TextField
              label="Nom de l'intervenant"
              defaultValue="Michal Baron"
              InputProps={{
                readOnly: true,
              }}
              variant="standard"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <MobileDatePicker
              label="Date de l'intervention"
              value={Date.now()}
              onChange={(value)=>console.log(value)}
              renderInput={(params) => 
                <TextField 
                  required 
                  variant="standard" 
                  fullWidth
                  {...params}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <EventIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <MultiPickerInput label="Intervenants supplémentaires" title="Choisir des intervenants" value={socialWorkers} onChange={handleOnExtraWorkerChange}/>
          </Grid>
          <Grid item xs={12} md={6}>
            <SinglePickerInput label="Équipe" options={sortBy(teams, (i)=>deburr(i.text))}  dialogTitle="Choisir un équipe" onChange={handleOnTeamChange}/>
          </Grid>
          <Grid item xs={12} md={6}>
            <SelectDialogInput label="Rejoint comment?" required={true} value="" options={reachoutOptions} dialogTitle="Sélectionner la facons que vous avez rejoint l'individu" onChange={handleOnReachOutChange}/>
          </Grid>
          <Grid item xs={12} md={6}>
            <SinglePickerInput label="Lieu de rencontre" required={true} options={sortBy(meetingPlaceOptions, (i)=>deburr(i.text))} dialogTitle="Sélectionner le lieu" onChange={handleOnTeamChange}/>
          </Grid>
          <Grid item xs={12} md={6}>
            <SelectDialogInput label="Type de lien" required={true} value="" options={linksOptions} dialogTitle="Sélectionner le type de lien avec l'individu" onChange={handleOnReachOutChange}/>
          </Grid>
          <Grid item xs={12} md={6}>
            <SelectDialogInput label="Statut de consommation?" required={true} value="" options={comsumationOptions} dialogTitle="Sélectionner le status de consomamation de l'individu" onChange={handleOnReachOutChange}/>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};
