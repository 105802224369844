import React from "react";
import { Box } from "@mui/system";
import { AppBar, Toolbar, IconButton, Typography, Stack } from "@mui/material";

interface EntityPageLayoutProps {
    leftIcon: React.ReactNode;
    leftIconOnClick: ()=>void;
    rightIcon?: React.ReactNode;
    rightIconOnClick?: ()=>void;
    title: string;
    children?: React.ReactNode;
}

export const EntityPageLayout: React.FC<EntityPageLayoutProps> = (props: EntityPageLayoutProps) => {

  return (
    <Box sx={{display:"flex", flexDirection:"column"}}>
      <AppBar color="primary">
        <Toolbar>
          <Stack direction='row' justifyContent="flex-start" alignItems="center" sx={{ flexGrow: 1 }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.leftIconOnClick}
            >
              {props.leftIcon}
            </IconButton>
            <Typography variant="h6" color="inherit" component="div">{props.title}</Typography>
          </Stack>
          <Stack direction='row' spacing={1}>
            <IconButton 
              edge="end"
              color="inherit"
              onClick={props.rightIconOnClick}>
              {props.rightIcon}
            </IconButton>
          </Stack>
        </Toolbar>
      </AppBar>
      <Toolbar />
      {props.children}
    </Box>
  );
};
