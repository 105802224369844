import { Avatar, ListItem, ListItemAvatar, ListItemButton, ListItemText } from "@mui/material";
import React from "react";
import { SinglepickerItemValue } from "./types";

export interface SinglePickerListItemProps {
  item: SinglepickerItemValue,
  onClick: ()=>void
}

export const SinglePickerListItem: React.FC<SinglePickerListItemProps> = (props: SinglePickerListItemProps) => {
  
  return (
    <ListItem disablePadding >
      <ListItemButton onClick={props.onClick}>        
        {props.item.imageSrc && (
          <ListItemAvatar>
            <Avatar src={props.item.imageSrc} variant="square"/>
          </ListItemAvatar>
        )}
        <ListItemText primary={props.item.text} secondary={props.item.secondary} />
      </ListItemButton>
    </ListItem>
  );
};