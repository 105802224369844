import { RealityTheme } from "types";

export const Themes: RealityTheme[] = [
  {
    id: 1,
    name: "Dépendance",
    description:"La dépendance désigne un état psychologique et/ou physique qui se manifeste par un besoin irrépressible et répété, jamais réellement assouvi. Celui-ci peut être lié à une consommation de produits tels l’alcool, le tabac et les psychotropes ou à un comportement tel la dépendance au jeu, au sexe et à l’internet.",
    specifications: [
      {
        id: 1,
        name: "Alcool"
      },
      {
        id: 2,
        name: "Drogue"
      },
      {
        id: 3,
        name: "Jeux"
      },
      {
        id: 4,
        name: "Sexe"
      },
      {
        id: 5,
        name: "Internet"
      },
      {
        id: 6,
        name: "Affectif"
      },
    ]
  },
  {
    id: 2,
    name: "Justice",
    description:"La <strong>justice</strong> est un principe moral de la vie sociale fondé sur la reconnaissance et le respect des autres. C’est aussi le pouvoir d’agir pour faire reconnaître et respecter ces droits.<br>La <strong>judiciarisation</strong> fait référence à la propension à privilégier le recours aux tribunaux pour trancher des litiges qui pourraient être réglés par d’autres voies (médiation, accord amiable).",
    specifications: [
      {
        id: 7,
        name: "Criminalité"
      },
      {
        id: 8,
        name: "Agression sexuelle"
      },
      {
        id: 9,
        name: "Délinquance"
      },
      {
        id: 10,
        name: "Taxage"
      },
      {
        id: 11,
        name: "DPJ"
      },
      {
        id: 12,
        name: "Judiciarisation"
      },
      {
        id: 13,
        name: "Droits et responsabilités"
      },
      {
        id: 14,
        name: "Vandalisme"
      },
      {
        id: 15,
        name: "Défense de droits"
      },
      {
        id: 16,
        name: "Fraude"
      },
      {
        id: 17,
        name: "Harcèlement"
      },
      {
        id: 18,
        name: "Intimidation"
      },
      {
        id: 19,
        name: "Violence conjugale"
      },
      {
        id: 20,
        name: "Violence sexuelle"
      },
      {
        id: 21,
        name: "Agression physique"
      },
      {
        id: 22,
        name: "Violence physique"
      },
      {
        id: 23,
        name: "Fugue"
      },
      {
        id: 24,
        name: "Violence"
      },
      {
        id: 25,
        name: "Violence psychologique"
      },
    ]
  },
  {
    id: 3,
    name: "Sexualité",
    description:"Ce qui est sexué, propre à chaque sexe, puis ensemble des comportements visant à la satisfaction de l’instinct sexuel (santé, plaisir, procréation) fait référence à « vie sexuelle » et aux « comportements sexuels ». La sexualité c’est l’ensemble des processus biologiques et psychologiques.",
    specifications: [
      {
        id: 26,
        name: "Contraception"
      },
      {
        id: 27,
        name: "Orientation sexuelle"
      },
      {
        id: 28,
        name: "Pornographie"
      },
      {
        id: 29,
        name: "Pratique sexuelle à risque"
      },
      {
        id: 30,
        name: "Sexualité"
      },
      {
        id: 31,
        name: "Agression sexuelle"
      },
      {
        id: 32,
        name: "Travesti"
      },
      {
        id: 34,
        name: "Déviance"
      },
      {
        id: 35,
        name: "Identité de genre"
      },
      {
        id: 36,
        name: "Prostitution"
      },
      {
        id: 37,
        name: "Avortement"
      },
      {
        id: 38,
        name: "ITSS"
      },
      {
        id: 39,
        name: "Santé sexuelle"
      },
      {
        id: 40,
        name: "Grossesse"
      },
      {
        id: 41,
        name: "Violence sexuelle"
      },
    ]
  },
  {
    id: 4,
    name: "Santé",
    description:"La santé est un état de bien-être complet physique, mental et social, et ne consiste pas seulement en une absence de maladie ou d’infirmité.",
    specifications: [
      {
        id: 42,
        name: "Santé mentale"
      },
      {
        id: 43,
        name: "Santé psychologique"
      },
      {
        id: 44,
        name: "Troubles alimentaires"
      },
      {
        id: 45,
        name: "ITSS /Santé sexuelle"
      },
      {
        id: 46,
        name: "Insalubrité"
      },
      {
        id: 47,
        name: "Santé physique"
      },
      {
        id: 48,
        name: "Suicide/Détresse"
      },
      {
        id: 49,
        name: "Avortement"
      },
      {
        id: 50,
        name: "Grossesse"
      },
      {
        id: 51,
        name: "Percing /Tatouage"
      },
    ]
  },
  {
    id: 5,
    name: "Relationnel",
    description:"Fait référence aux compétences relationnelles de l’individu, c’est-à-dire : sa capacité à établir un rapport avec une personne, sa capacité d’écoute, sa capacité à dire ce qui va et ce qui ne va pas, sa capacité d’influence, sa capacité à gérer ses perceptions, sa capacité à gérer des situations émotionnellement difficiles.",
    specifications: [
      {
        id: 52,
        name: "Intimidation/Taxage"
      },
      {
        id: 53,
        name: "Isolement"
      },
      {
        id: 54,
        name: "Relation avec les pairs"
      },
      {
        id: 55,
        name: "Fugue"
      },
      {
        id: 56,
        name: "Préjugés (racisme, homophobie)"
      },
      {
        id: 57,
        name: "Relation familiale"
      },
      {
        id: 58,
        name: "Relation amoureuse"
      },
      {
        id: 59,
        name: "Rôle parental"
      }
    ]
  },
  {
    id: 6,
    name: "Cheminement personnel",
    description:"Démarches visant l’épanouissement de la personne vers ce qu’elle aspire à devenir. Tout ce qui est ou qui peut être mis en oeuvre pour acquérir une maturité ou une autonomie plus grande.",
    specifications: [
      {
        id: 60,
        name: "Secte/Religion/Spiritualité"
      },
      {
        id: 61,
        name: "Rue, mode de vie"
      },
      {
        id: 62,
        name: "Estime de soi"
      },
      {
        id: 63,
        name: "Vécu institutionnel"
      },
      {
        id: 64,
        name: "Estime de soi"
      },
      {
        id: 65,
        name: "Loisirs/projets"
      },
      {
        id: 66,
        name: "Autonomie"
      },
      {
        id: 67,
        name: "Deuil"
      },
      {
        id: 68,
        name: "Identité de genre"
      }
    ]
  },
  {
    id: 7,
    name: "Socioéconomique",
    description:"Relatif aux phénomènes sociaux, économiques et à leurs relations. Se décline en statuts socioéconomiques qui sont déterminés entre autres par le revenu, la scolarité, l’emploi, l’accès aux ressources et aux services. (facteur socioéconomique)",
    specifications: [
      {
        id: 69,
        name: "Aide alimentaire"
      },
      {
        id: 70,
        name: "Finances"
      },
      {
        id: 71,
        name: "Aide sociale"
      },
      {
        id: 72,
        name: "Hébergement"
      },
      {
        id: 73,
        name: "Budget"
      },
      {
        id: 74,
        name: "Logement"
      },
      {
        id: 75,
        name: "Itinérance"
      },
      {
        id: 76,
        name: "Besoins de base"
      },
      {
        id: 77,
        name: "Carte et identité"
      },
      {
        id: 78,
        name: "Chômage"
      },
      {
        id: 79,
        name: "Pauvreté"
      },
      {
        id: 80,
        name: "Employabilité"
      },
      {
        id: 81,
        name: "Scolarité"
      },
      {
        id: 82,
        name: "Endettement"
      },
      {
        id: 83,
        name: "Errance"
      },
      {
        id: 84,
        name: "Rue, mode de vie"
      },
      {
        id: 85,
        name: "Immigration"
      }
    ]
  }
];