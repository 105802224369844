import React from "react";
import { Dialog, Slide, List, Paper } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { TransitionProps } from "@mui/material/transitions";
import { EntityPageLayout } from "components/Layout";
import { SinglepickerItemValue } from "./types";
import { SinglePickerListItem } from "./SinglePickerListItem";
import DeselectIcon from "@mui/icons-material/Deselect";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<unknown>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export interface SinglePickerDialogProps {
  title: string,
  isDialogOpen: boolean,
  handleDialogClose: ()=>void,
  value: SinglepickerItemValue[],
  onChange: (selected?: SinglepickerItemValue)=>void,
  hideDeselect?:boolean
}

export const SinglePickerDialog: React.FC<SinglePickerDialogProps> = (props: SinglePickerDialogProps) => {

  const handleOnClick = (item:SinglepickerItemValue) => {
    props.onChange(item);
  };

  return (
    <Dialog
      fullScreen
      open={props.isDialogOpen}
      onClose={props.handleDialogClose}
      TransitionComponent={Transition}
    >
      <EntityPageLayout
        leftIcon={<ArrowBackIcon />}
        leftIconOnClick={props.handleDialogClose}
        rightIcon={props.hideDeselect ? <DeselectIcon /> : null}
        rightIconOnClick={()=>props.onChange(undefined)}
        title={props.title}
      >
        <Paper>
          <List>
            {props.value.map((item)=>(
              <SinglePickerListItem key={item.id} item={item} onClick={()=>handleOnClick(item)} />
            ))}
          </List>
        </Paper>
      </EntityPageLayout>
    </Dialog>
  );
};