import React from "react";
import { Dialog, List, DialogTitle, ListItem, ListItemText } from "@mui/material";
import { SelectDialogItemValue } from "./types";

export interface SelectDialogProps {
  isDialogOpen: boolean,
  title: string,
  handleDialogClose: ()=>void,
  value: SelectDialogItemValue[],
  onChange: (selected: SelectDialogItemValue)=>void,
}

export const SelectDialog: React.FC<SelectDialogProps> = (props: SelectDialogProps) => {

  const handleOnClick = (item:SelectDialogItemValue) => {
    props.onChange(item);
  };

  return (
    <Dialog onClose={props.handleDialogClose} open={props.isDialogOpen}>
      <DialogTitle>{props.title}</DialogTitle>
      <List sx={{ pt: 0 }}>
        {props.value.map((item) => (
          <ListItem button onClick={() => handleOnClick(item)} key={item.id}>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
};