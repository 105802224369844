import React from "react";
import { ListItem, ListItemButton, Stack, InputLabel, FormControl, Chip, Typography } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { MultiPickerDialog } from "./MultiPickerDialog";
import { MultipickerItemValue } from "./types";
import sortBy from "lodash/sortBy";

export interface MultiPickerInputProps {
  title: string,
  label: string,
  value: MultipickerItemValue[],
  onChange: (selected: MultipickerItemValue) => void
  renderAvatar?: boolean;
}

export const MultiPickerInput: React.FC<MultiPickerInputProps> = ({
  title,
  label,
  value,
  onChange,
  renderAvatar = true
}: MultiPickerInputProps) => {

  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [selected, setSelected] = React.useState<MultipickerItemValue[]>([]);

  const handleClickOpenButton = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleOnSelected = (selection: MultipickerItemValue[]) => {
    setSelected(selection);
  };

  return (
    <>
      <FormControl fullWidth sx={{borderBottomColor: "text.disabled", borderBottomStyle:"solid", borderBottomWidth: 1}}>
        <InputLabel variant='standard' shrink>{label}</InputLabel>
        <ListItem disablePadding sx={{mt:2}}>
          <ListItemButton disableGutters sx={{py:1/2}} onClick={handleClickOpenButton}>
            <Stack direction='row' justifyContent="space-between" sx={{flexGrow:1}}>
              <Stack direction='row' gap={1/2} flexWrap="wrap">
                {selected.map((item)=>(
                  <Chip key={item.id} label={item.name} size="small"/>
                ))}
                {selected.length === 0 && (
                  <Typography>Sélectionner...</Typography>
                )}
              </Stack>
            </Stack>
            <ChevronRightIcon />
          </ListItemButton>
        </ListItem>
      </FormControl>
      <MultiPickerDialog
        title={title}
        isDialogOpen={isDialogOpen} 
        handleDialogClose={handleDialogClose} 
        value={sortBy(value, ["name"])} 
        selected={sortBy(selected, ["name"])}
        onChange={handleOnSelected}
        renderAvatar={renderAvatar}
      />
    </>
  );
};