import React from "react";
import { Stack, Chip, Dialog, Slide, styled, TextField, InputAdornment, List, Paper } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { TransitionProps } from "@mui/material/transitions";
import { EntityPageLayout } from "components/Layout";
import SearchIcon from "@mui/icons-material/Search";
import { MultiPickerListItem } from "./MultiPickerListItem";
import { MultipickerItemValue } from "./types";
import deburr from "lodash/deburr";
import differenceBy from "lodash/differenceBy";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<unknown>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const SearchTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
    }
  },
});

export interface MultiPickerDialogProps {
  title: string,
  isDialogOpen: boolean,
  handleDialogClose: ()=>void,
  value: MultipickerItemValue[],
  selected: MultipickerItemValue[],
  onChange: (selected: MultipickerItemValue[])=>void,
  renderAvatar: boolean,
}

export const MultiPickerDialog: React.FC<MultiPickerDialogProps> = (props: MultiPickerDialogProps) => {

  const [textSearch, setTextSearch] = React.useState("");

  const handleOnDelete = (item:MultipickerItemValue) => {
    props.onChange(props.selected.filter(x => x.id !== item.id));
  };

  const handleOnClick = (item:MultipickerItemValue) => {
    props.onChange([...props.selected, item]);
  };

  const handleSearchOnChange = (event : React.ChangeEvent<HTMLInputElement>) => {
    setTextSearch(event.target.value);
  };

  const valuesLefted = differenceBy(props.value, props.selected, "id");

  const filteredValue = valuesLefted.filter(
    (result) => deburr(Object.values([result.name]).toString())
      .toLowerCase()
      .indexOf(deburr(textSearch).toLowerCase()) > -1
  );

  return (
    <Dialog
      fullScreen
      open={props.isDialogOpen}
      onClose={props.handleDialogClose}
      TransitionComponent={Transition}
    >
      <EntityPageLayout
        leftIcon={<ArrowBackIcon />}
        leftIconOnClick={props.handleDialogClose}
        title={props.title}
      >
        <Stack sx={{backgroundColor: "background.paper"}}>
          <SearchTextField
            fullWidth 
            placeholder="Rechercher..." 
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={handleSearchOnChange}
          />
        </Stack>
        <Stack direction='row' sx={{my:1, px:1}} gap={1/2} flexWrap="wrap">
          {props.selected.map((item)=>(
            <Chip key={item.id} label={item.name} onDelete={()=>handleOnDelete(item)} />
          ))}
        </Stack>
        <Paper>
          <List>
            {filteredValue.map((item)=>(
              <MultiPickerListItem key={item.id} name={item.name} avatarSrc={item.avatarSrc} onClick={()=>handleOnClick(item)} renderAvatar={props.renderAvatar}/>
            ))}
          </List>
        </Paper>
      </EntityPageLayout>
    </Dialog>
  );
};