import React from "react";
import { Card, CardActionArea, CardContent, Grid, Stack, Typography } from "@mui/material";
import AppsIcon from "@mui/icons-material/Apps";

export interface SelectDistributionCardProps {
  label: string,
  imageSrc?: string,
  onClick: ()=>void,
}

export const SelectDistributionCard: React.FC<SelectDistributionCardProps> = (props: SelectDistributionCardProps) => {

  return (
    <Grid item xs={6} md={3}>
      <Card sx={{height:"100%"}}>
        <CardActionArea sx={{height:"100%"}} onClick={props.onClick}>
          <CardContent sx={{height:"100%"}}>
            <Stack sx={{height:"100%"}}>
              <Stack 
                justifyContent="center"
                alignItems="center"
                sx={{height:"100%"}}>
                {props.imageSrc ? (
                  <img src={props.imageSrc} loading="lazy" style={{width: "100%"}} />
                ): (
                  <AppsIcon sx={{ fontSize: 100 }} color="primary"/>
                )}
              </Stack>
              <Typography variant="h5" component="div" color="primary" align="center">{props.label}</Typography>
            </Stack>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};