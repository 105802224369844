import React from "react";
import { Stack, Chip, Dialog, Slide, List, Paper } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { TransitionProps } from "@mui/material/transitions";
import { EntityPageLayout } from "components/Layout";
import { SelectSpecificationItemValue } from "./types";
import { RealityTheme } from "types";
import { ThemeListItem } from "./ThemeListItem";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<unknown>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export interface SelectSpecificationsDialogProps {
  title: string,
  isDialogOpen: boolean,
  handleDialogClose: ()=>void,
  value: RealityTheme[],
  selected: SelectSpecificationItemValue[],
  onChange: (selected: SelectSpecificationItemValue[])=>void,
}

export const SelectSpecificationsDialog: React.FC<SelectSpecificationsDialogProps> = (props: SelectSpecificationsDialogProps) => {

  const handleOnDelete = (item:SelectSpecificationItemValue) => {
    props.onChange(props.selected.filter(x => x.id !== item.id));
  };

  return (
    <Dialog
      fullScreen
      open={props.isDialogOpen}
      onClose={props.handleDialogClose}
      TransitionComponent={Transition}
    >
      <EntityPageLayout
        leftIcon={<ArrowBackIcon />}
        leftIconOnClick={props.handleDialogClose}
        title={props.title}
      >
        <Stack direction='row' sx={{my:1, px:1}} gap={1/2} flexWrap="wrap">
          {props.selected.map((item)=>(
            <Chip key={item.id} label={`${item.theme.name} - ${item.name}`} onDelete={()=>handleOnDelete(item)} />
          ))}
        </Stack>
        <Paper>
          <List disablePadding>
            {props.value.map((theme) => (
              <ThemeListItem key={theme.id} theme={theme} selected={props.selected} onChange={props.onChange}/>
            ))}
          </List>
        </Paper>
      </EntityPageLayout>
    </Dialog>
  );
};