import { Avatar, ListItem, ListItemAvatar, ListItemButton, ListItemText } from "@mui/material";
import React from "react";

export interface MultiPickerListItemProps {
  name: string,
  avatarSrc?: string,
  onClick: ()=>void,
  renderAvatar: boolean
}

export const MultiPickerListItem: React.FC<MultiPickerListItemProps> = (props: MultiPickerListItemProps) => {

  return (
    <ListItem disablePadding >
      <ListItemButton onClick={props.onClick}>
        {props.renderAvatar && (
          <ListItemAvatar>
            <Avatar src={props.avatarSrc} />
          </ListItemAvatar>
        )}

        <ListItemText primary={props.name} />
      </ListItemButton>
    </ListItem>
  );
};