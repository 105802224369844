import React from "react";
import { Container, Paper, Typography, Divider, Stack, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { SelectSpecificationsInput } from "components/SelectSpecifications";
import { Themes } from "modules/interventions/RealityThemes";
import { MultiPickerInput } from "components/MultiPicker";
import { MultipickerItemValue } from "components/MultiPicker/types";

const approches: MultipickerItemValue[] = [
  { id: 1, name: "Approche 1" },
  { id: 2, name: "Approche 2"},
  { id: 3, name: "Approche 3"},
  { id: 4, name: "Approche 4" },
  { id: 5, name: "Approche 5" },
];

const references: MultipickerItemValue[] = [
  { id: 1, name: "Reférence 1" },
  { id: 2, name: "Reférence 2"},
  { id: 3, name: "Reférence 3"},
  { id: 4, name: "Reférence 4" },
  { id: 5, name: "Reférence 5" },
];

const accompagnements: MultipickerItemValue[] = [
  { id: 1, name: "Accompagnement 1" },
  { id: 2, name: "Accompagnement 2"},
  { id: 3, name: "Accompagnement 3"},
  { id: 4, name: "Accompagnement 4" },
  { id: 5, name: "Accompagnement 5" },
];

export const NewInterventionSection3: React.FC = () => {

  const handleOnSpecificationsChange = () => {
    console.log("");
  };

  return (
    <Container disableGutters sx={{py:2, px:1}}>
      <Stack gap={2}>
        <Paper>
          <Box sx={{p:1}}>
            <Typography variant="h6" component="h2" fontWeight={700}>Réalités</Typography>
          </Box>
          <Divider />
          <Grid container spacing={2} sx={{p:1}}>
            <Grid item xs={12} md={6}>
              <SelectSpecificationsInput label="Spécifications" title="Choisir des spécifications" value={Themes} onChange={handleOnSpecificationsChange}/>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{p:1}}>
            <Grid item xs={12} md={6}>
              <MultiPickerInput label="Approches" title="Choisir des approches" value={approches} onChange={handleOnSpecificationsChange} renderAvatar={false}/>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{p:1}}>
            <Grid item xs={12} md={6}>
              <MultiPickerInput label="Références" title="Choisir des références" value={references} onChange={handleOnSpecificationsChange} renderAvatar={false}/>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{p:1}}>
            <Grid item xs={12} md={6}>
              <MultiPickerInput label="Accompagnements" title="Choisir des accompagnement" value={accompagnements} onChange={handleOnSpecificationsChange} renderAvatar={false}/>
            </Grid>
          </Grid>
        </Paper>
      </Stack>
    </Container>
  );
};
