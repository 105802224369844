import React from "react";
import { ListItem, ListItemButton, Stack, InputLabel, FormControl, Typography } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { SinglepickerItemValue } from "./types";
import { SinglePickerDialog } from "./SinglePickerDialog";

export interface SinglePickerInputProps {
  dialogTitle: string,
  label: string,
  options: SinglepickerItemValue[],
  onChange: (selected: SinglepickerItemValue) => void,
  required?: boolean,
  hideDeselect?: boolean,
}

export const SinglePickerInput: React.FC<SinglePickerInputProps> = (props: SinglePickerInputProps) => {

  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [selected, setSelected] = React.useState<SinglepickerItemValue>();

  const handleClickOpenButton = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleOnSelected = (selection?: SinglepickerItemValue) => {
    setSelected(selection);
    setIsDialogOpen(false);
  };

  return (
    <>
      <FormControl fullWidth sx={{borderBottomColor: "text.disabled", borderBottomStyle:"solid", borderBottomWidth: 1}} required={props.required}>
        <InputLabel variant='standard' shrink>{props.label}</InputLabel>
        <ListItem disablePadding sx={{mt:2}}>
          <ListItemButton disableGutters sx={{py:1/2}} onClick={handleClickOpenButton}>
            <Stack direction='row' justifyContent="space-between" sx={{flexGrow:1}}>
              <Stack direction='row' gap={1/2} flexWrap="wrap">
                {selected == null ? (
                  <Typography>Sélectionner...</Typography>
                ) : (
                  <Typography>{selected?.text}</Typography>
                )}
              </Stack>
            </Stack>
            <ChevronRightIcon />
          </ListItemButton>
        </ListItem>
      </FormControl>
      <SinglePickerDialog
        title={props.dialogTitle}
        isDialogOpen={isDialogOpen}
        hideDeselect={props.hideDeselect}
        handleDialogClose={handleDialogClose} 
        value={props.options} 
        onChange={handleOnSelected}
      />
    </>
  );
};