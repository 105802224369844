import React from "react";
import { ListItem, ListItemButton, ListItemText } from "@mui/material";
import { Individual } from "types";
import localizedFormat from "dayjs/plugin/localizedFormat";
import dayjs from "dayjs";

dayjs.extend(localizedFormat);

export interface IndividualPickerListItemProps {
  item: Individual,
  onClick: ()=>void
}

export const IndividualPickerListItem: React.FC<IndividualPickerListItemProps> = (props: IndividualPickerListItemProps) => {
  
  return (
    <ListItem disablePadding >
      <ListItemButton onClick={props.onClick}>        
        <ListItemText 
          primary={`${props.item.firstName} ${props.item.lastName} - ${props.item.surname}`} 
          secondary={`${props.item.age} ans (${dayjs(props.item.birthdate).format("LL")})`} />
      </ListItemButton>
    </ListItem>
  );
};