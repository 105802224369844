import React from "react";
import { Box, Button, Container, Divider, IconButton, Paper, Stack, Tab, Tabs, Typography } from "@mui/material";
import { EntityPageLayout } from "components/Layout";
import CloseIcon from "@mui/icons-material/Close";
import PeopleIcon from "@mui/icons-material/People";
import EditIcon from "@mui/icons-material/Edit";

export interface IndividualPageProps {
  handleDialogClose: ()=>void,
}

export const IndividualPage: React.FC<IndividualPageProps> = (props: IndividualPageProps) => {

  return (
    <EntityPageLayout 
      title="Individu"
      leftIcon={<CloseIcon />}
      leftIconOnClick={props.handleDialogClose}
    >
      <Container sx={{py:2}}>
        <Stack direction='row' alignItems='center' gap={1}>
          <PeopleIcon color='primary'/>
          <Typography variant="h5" component="h1" fontWeight={700}>Alex - Freddy mercury</Typography>
        </Stack>
      </Container>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Container disableGutters sx={{px:1}}>
          <Tabs value={0}>
            <Tab label="Information" />
            <Tab label="Interventions" />
          </Tabs>
        </Container>
      </Box>
      <Container disableGutters sx={{py:2, px:1}}>
        <Paper>
          <Stack direction='row' alignItems="center" justifyContent="space-between" sx={{p:1}}>
            <Typography variant="h6" component="h2" fontWeight={700}>Détails</Typography>
            <IconButton aria-label="Edit">
              <EditIcon />
            </IconButton>
          </Stack>
          <Divider />
          <Stack direction='row' alignItems="center" justifyContent="space-between" sx={{p:1}}>
            <Typography>Surnom</Typography>
            <Typography sx={{color:"grey.600"}}>Alex</Typography>
          </Stack>
          <Divider />
          <Stack direction='row' alignItems="center" justifyContent="space-between" sx={{p:1}}>
            <Typography>Nom</Typography>
            <Typography sx={{color:"grey.600"}}></Typography>
          </Stack>
          <Divider />
          <Stack direction='row' alignItems="center" justifyContent="space-between" sx={{p:1}}>
            <Typography>Prénom</Typography>
            <Typography sx={{color:"grey.600"}}>Freddy mercury</Typography>
          </Stack>
          <Divider />
          <Stack direction='row' alignItems="center" justifyContent="space-between" sx={{p:1}}>
            <Typography>Âge</Typography>
            <Typography sx={{color:"grey.600"}}>28</Typography>
          </Stack>
          <Divider />
          <Stack direction='row' alignItems="center" justifyContent="space-between" sx={{p:1}}>
            <Typography>Date de naissance</Typography>
            <Typography sx={{color:"grey.600"}}>1 janvier 1993</Typography>
          </Stack>
        </Paper>
      </Container>
      <Container disableGutters sx={{py:2, px:1}}>
        <Paper sx={{borderColor:"error.main", borderStyle:"solid", borderWidth:1}}>
          <Typography variant="h6" component="h2" fontWeight={700} sx={{p:1}}>Zone dangeureuse</Typography>
          <Divider sx={{borderColor:"error.main"}}/>
          <Box sx={{p:1}}>
            <Typography fontWeight={700}>Archiver</Typography>
            <Typography>Mettre en archive cet individu et en lecture seule.</Typography>
            <Stack direction='row' justifyContent="flex-end">
              <Box>
                <Button variant="contained" color="error">Achiver cet individu</Button>
              </Box>
            </Stack>
          </Box>
          <Divider/>
          <Box sx={{p:1}}>
            <Typography fontWeight={700}>Supprimer</Typography>
            <Typography>Une fois supprimer, il n’est pas possible de revenir en arrière.</Typography>
            <Stack direction='row' justifyContent="flex-end">
              <Box>
                <Button variant="contained" color="error">Supprimer cet individu</Button>
              </Box>
            </Stack>
          </Box>
        </Paper>
      </Container>
    </EntityPageLayout>
  );
};