import { Collapse, Dialog, DialogContent, DialogContentText, DialogTitle, IconButton, IconButtonProps, List, ListItem, ListItemButton, ListItemIcon, ListItemText, styled } from "@mui/material";
import React from "react";
import { RealitySpecification, RealityTheme } from "types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import parse from "html-react-parser";
import { SpecificationListItem } from "./SpecificationListItem";
import { SelectSpecificationItemValue } from "./types";
import sortBy from "lodash/sortBy";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
};

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export interface ThemeListItemProps {
  theme: RealityTheme,
  selected: SelectSpecificationItemValue[],
  onChange: (selected: SelectSpecificationItemValue[])=>void,
}

export const ThemeListItem: React.FC<ThemeListItemProps> = (props: ThemeListItemProps) => {
  const [expanded, setExpanded] = React.useState(false);
  const [isThemeDialogOpen, setIsThemeDialogOpen] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleInfoClick = () => {
    setIsThemeDialogOpen(true);
  };

  const handleOnCloseDialog = () => {
    setIsThemeDialogOpen(false);
  };

  const handleOnChange = (checked: boolean, item: RealitySpecification) => {
    if(checked){
      const selectedItem: SelectSpecificationItemValue = {
        id: item.id,
        name: item.name,
        theme: props.theme
      };
      props.onChange([...props.selected, selectedItem]);
    }
    else{
      props.onChange(props.selected.filter(x => x.id !== item.id));
    }
  };

  return (
    <>
      <ListItem
        disablePadding
        secondaryAction={
          <IconButton edge="end" onClick={handleInfoClick}>
            <InfoIcon />
          </IconButton>
        }>
        <ListItemButton disableGutters onClick={handleExpandClick}>
          <ListItemIcon>
            <ExpandMore expand={expanded}>
              <ExpandMoreIcon />
            </ExpandMore>
          </ListItemIcon>
          <ListItemText primary={props.theme.name}/>
        </ListItemButton>
      </ListItem>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <List component="div">
          {sortBy(props.theme.specifications, ["name"]).map((specification) => (
            <SpecificationListItem 
              key={specification.id} 
              specification={specification} 
              isChecked={props.selected.some(x => x.id == specification.id)} 
              onChange={handleOnChange}
            />
          ))}
        </List>
      </Collapse>
      <Dialog
        open={isThemeDialogOpen}
        onBackdropClick={handleOnCloseDialog}
        onClose={handleOnCloseDialog}
      >
        <DialogTitle>
          {props.theme.name}
          <IconButton
            onClick={handleOnCloseDialog}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{parse(props.theme.description)}</DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};